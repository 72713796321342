/*
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

// GENERAL
// ---------------

$color1:              #161718;
$color2:              #004987;
$color3:              #E1251B;
$color4:              #F4DB6C;
$color5:              #85754E;
$color6:              #F2F5F9;
$color7:              #E4EBF2;
$color8:              #00284A;
$color9:              #8E0700;
$color10:             #001F39;

// TYPOGRAPHY
// ---------------

$textColor:           #333;

$baseFontSize:        16px;
$em-base:             $baseFontSize;
$baseLineHeight:      1.612;
$baseTypeSpacing:     round($baseFontSize * $baseLineHeight);
$baseFontFamily:      'Roboto', sans-serif;
$secondaryFontFamily: $baseFontFamily;

$headingsFontFamily:  $secondaryFontFamily;
$headingsColor:       $color1;

$hrColor:             #DDD;
